
import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import tailwindConfig from '../../../tailwind.config';
import { TailwindConfig } from './tailwindType'

export const tailwindTheme:TailwindConfig = {...tailwindConfig.theme, ...tailwindConfig.theme.extend}


const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={tailwindTheme}>{element}</ThemeProvider>
);
export default wrapRootElement;
