const tailwindDefault = require('./src/config/theme/tailwind.default');

const { theme: defaultTheme } = tailwindDefault;
const {
  colors, spacing, fontFamily, borderWidth, fontSize, fontWeight,
} = defaultTheme;

module.exports = {
  purge: [],
  theme: {
    extend: {
      ...defaultTheme,
      colors: {
        ...colors,
        primary: '#61F3AC',
        secondary: '#006FAF',
        primaryDark: '#547A82',
        pirmaryLight: '#A2D4AB',
        lightGray: '#707070',
        gray: '#6a295f',
        behaviorColor: '#009AC0',
        background: '#F2F3F4',
        primary21: '#B34C79',
        primary22: '#FF7079',
        salmon: '#F8D9C4',
        mixColor: '#008e83',
      },

      spacing: {
        ...spacing,
        96: '24rem',
        128: '32rem',
      },
      fontFamily: {
        ...fontFamily,
        display: ['Gilroy', 'sans-serif'],
        body: ['Graphik', 'sans-serif'],
      },
      fontSize: {
        ...fontSize,
      },
      fontWeight: {
        ...fontWeight,
      },
      borderWidth: {
        ...borderWidth,
        default: '1px',
        0: '0',
        2: '2px',
        4: '4px',
      },
    },
  },
  variants: {},
  plugins: [],
};
