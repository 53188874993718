// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dev-drag-tsx": () => import("./../../../src/pages/dev/drag.tsx" /* webpackChunkName: "component---src-pages-dev-drag-tsx" */),
  "component---src-pages-dev-fullpage-tsx": () => import("./../../../src/pages/dev/fullpage.tsx" /* webpackChunkName: "component---src-pages-dev-fullpage-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-muses-tsx": () => import("./../../../src/pages/muses.tsx" /* webpackChunkName: "component---src-pages-muses-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-stories-tsx": () => import("./../../../src/pages/stories.tsx" /* webpackChunkName: "component---src-pages-stories-tsx" */),
  "component---src-templates-story-story-page-tsx": () => import("./../../../src/templates/story/storyPage.tsx" /* webpackChunkName: "component---src-templates-story-story-page-tsx" */)
}

